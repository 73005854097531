
/* CSS overrides of the predefined layout */

/* Changing color of selected entry in navigation bar */
ul#nav li.current a { color: #9d9d9d; }

/* Overriding header text color */
header .banner-text h3 {
	color: #525252;
}

/* Changing color of selected entry in navigation bar */
@media only screen and (max-width: 767px) {
	#nav-wrap > a {
		background-color: #9d9d9d;
	}
}

/* Opacity 75% */
.opacity-75 { opacity: 75% }

footer .language-links li {
	display: inline-block;
	padding-left: 10px;
}

#about {
	background-color: #096a6e;
}

 #go-top a:hover {
 	background-color: #096a6e;
 }

.impressum-content {
	margin: 50px;
}

.impressum-header {
	background-color: white;
	padding: 30px;
	padding-left: 50px;
}

.impressum-content h1, h2, h3, h4  {
	color: #bbbbbb;
}

.impressum-page .emoji {
	height: 18px;
	background-color: transparent;
}

.impressum-page img {
	background-color:  white;
	padding: 3px;
}


.services-pic {
	width: 250px;
	height: 250px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	border: 10px solid #ececec;
}

.services-pic:hover {
	border: 10px solid #096a6e;
}


.quote-author {

}
.quote-text {
	font-style: italic;
}

.about-pic {
	width: 100px;
}

.certificate-row {
	margin-bottom: 50px;
}


#services {
    background: #191919;
    padding-top: 96px;
    padding-bottom: 102px;
    color: #636363;
}

#contact a, #contact a:visited {
    color: #096a6e;
}

.contact-entry {
	font-weight: bold;
}

.certificate-row p {
	margin-bottom: 5px;
}